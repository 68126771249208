import * as React from "react";
import { Disclosure, Transition } from '@headlessui/react'
import { ChevronUpIcon } from "@heroicons/react/solid";

export default function Accordion(props){
    return(
        <section className="text-gray-600 body-font homeHero pt-16 pb-10">
            {/* style={{backgroundImage : `url(${props.content.primary.image.url})`, backgroundRepeat: 'no-repeat', backgroundPosition: `bottom ${props.content.primary.image_side}`, backgroundSize: `${props.content.primary.image_size} auto`}} */}
            <div className={`container px-5 mx-auto`}>
                <div className={`flex flex-wrap flex-col ml-24`}>
                    {props.content.primary.section_name!== "" &&
                        <h5 className="sm:text-base text-lg uppercase font-normal title-font mb-10 text-elite-light">{props.content.primary.section_name}</h5>
                    }
                    {props.content.primary.section_title.text &&
                        <h2 className="sm:text-4xl text-4xl font-extralight title-font mb-20 text-gray-900">{props.content.primary.section_title.text}</h2>
                    }
                    {props.content.primary.section_content.text &&
                        <p className="lg:w-1/2 w-full leading-relaxed text-gray-500 mb-10">{props.content.primary.section_content.text}</p>
                    }
                    {props.content.items.map(accordion => (
                        <>
                            {/* <Disclosure 
                                title={accordion.item_title} 
                                icon={accordion.item_image.url} 
                                content={accordion.item_content.text}
                            /> */}
                            <Disclosure>
                                {({ open }) => (
                                    <>
                                    <Disclosure.Button className="flex items-center justify-between py-2 text-lg border-b-2 w-1/2 focus:outline-none">
                                        <div className="flex items-center">
                                            <img className=" h-16 pr-2" src={accordion.item_image.url} alt="some description" />
                                            {accordion.item_title}
                                        </div>
                        
                                        <ChevronUpIcon
                                            className={`${
                                                open ? "transform rotate-180" : ""
                                            } w-5 h-5 text-purple-500`}
                                        />
                                        
                                    </Disclosure.Button>
                                    <Transition
                                        show={open}
                                        enter="transition duration-100 ease-out"
                                        enterFrom="transform scale-95 opacity-0"
                                        enterTo="transform scale-100 opacity-100"
                                        leave="transition duration-75 ease-out"
                                        leaveFrom="transform scale-100 opacity-100"
                                        leaveTo="transform scale-95 opacity-0"
                                    >
                                        <Disclosure.Panel static className="p-4 w-1/2 bg-white mt-4">
                                            {accordion.item_content.text}
                                        </Disclosure.Panel>
                                    </Transition>
                                    </>
                                )}
                            </Disclosure>

                        </>
                    ))}
                </div>
            </div>
        </section>
    )
}