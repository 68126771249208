import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/parts/hero"
import Section from "../components/parts/section"
import Stats from "../components/parts/stats"
import HalfImageHalfContent from "../components/parts/halfimagehalfcontent"
import CTA from "../components/parts/cta"
import ContactForm from "../components/parts/contact"
import Accordion from "../components/parts/accordion"

const servicesPage = ({data}) => (
  <Layout>
    <SEO title={data.allPrismicService.nodes[0].data.title.text!==null?data.allPrismicService.nodes[0].data.title.text:"Test"} />
    {data.allPrismicService.nodes.map(page => (
      <>
        {page.data.body.map( section => (
          <div className="flex flex-wrap">
            <div className="w-1/4"></div>
            <div className="w-3/4">
              {section.slice_type === "hero_section" &&
                <Hero content={section} />  
              }
              {section.slice_type === "generic_section" &&
                <Section content={section} alignment="Left" />  
              }
              {section.slice_type === "image_with_stats" &&
                <Stats content={section} />  
              }
              {section.slice_type === "1_2___1_2" &&
                <HalfImageHalfContent content={section} />  
              }
              {section.slice_type === "call_to_action" &&
                <CTA content={section} />  
              }
              {section.slice_type === "contact_form" &&
                <ContactForm content={section} />  
              }
              {section.slice_type === "accordion" &&
                <Accordion content={section} />  
              }
            </div>
          </div>
        ))}
      </>
    ))}
  </Layout>
)

export default servicesPage

export const query = graphql`
  query ServiceQuery($uid: String)
  {
    allPrismicService(filter:{ uid: { eq: $uid } }) {
      nodes {
        uid
        data {
          meta_title
          meta_description
          title {
            text
          }
          body {
            ... on PrismicServiceBodyHeroSection {
              id
              slice_type
              primary {
                section_name
                background_color
                background_image {
                  url
                }
                hero_title {
                  text
                }
                hero_content {
                  text
                }
              }
            }
            ... on PrismicServiceBodyGenericSection {
              id
              slice_type
              slice_label
              primary {
                section_name
                section_background {
                  url
                  alt
                }
                background_size
                bg_position
                section_title {
                  text
                }
                section_content {
                  text
                }
                call_to_action
                button_text
                button_link {
                  url
                }
                icon_columns
              }
              items {
                iwt_image {
                  alt
                  url
                }
                iwt_title {
                  text
                }
                iwt_content {
                  text
                }
                iwt_link {
                  url
                }
              }
            }
            ... on PrismicServiceBodyImageWithStats {
              id
              slice_type
              slice_label
              primary {
                section_name
                section_title {
                  text
                }
                content {
                  text
                }
                image {
                  alt
                  url
                }
              }
              items {
                stat_icon {
                  alt
                  url
                }
                key_fact
                fact_measurement
              }
            }
            ... on PrismicServiceBody1212 {
              id
              slice_type
              slice_label
              primary {
                section_name
                image {
                  alt
                  url
                }
                image_side
                image_size
                section_title {
                  text
                }
                section_content {
                  text
                }
              }
            }
            ... on PrismicServiceBodyCallToAction {
              id
              slice_type
              slice_label
              primary {
                section_name
                cta_image {
                  alt
                  url
                }
                cta_title {
                  text
                }
                cta_content {
                  text
                }
                button_text
                button_link {
                  url
                }
              }
            }
            ... on PrismicServiceBodyContactForm {
              id
              slice_type
              slice_label
              primary {
                section_name
                email_addresses
              }
            }
            ... on PrismicServiceBodyAccordion {
                id
                slice_type
                primary {
                  section_name
                  section_title {
                    text
                  }
                  section_content {
                    text
                  }
                }
                items {
                  item_image {
                    alt
                    url
                  }
                  item_title
                  item_content {
                    text
                  }
                }
            }
          }
        }
      }
    }
  }
`